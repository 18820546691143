.video-player__wrapper {
  margin: 0 auto;
  max-width: 1200px;
  padding-top: 56.25%;
  position: relative;
  width: 80vw;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}