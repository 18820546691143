@import '../../../stylesheets/base/variables.scss';

.site-header__title {
  display: block;
  margin: 0 auto;
  max-width: 650px;
  text-align: center;
  text-decoration: none;

  > h1 {
    display: inline;

    @include mobile-only {
      line-height: 1.1;
    }
  }
}

.site-header__subtitle {
  margin: 25px auto 0;
  text-align: center;
  font-family: $sub-header-font-family;
  font-size: 22px;
  letter-spacing: 3.5px;
  color: $subheader-color;

  @include tablet-and-larger {
    margin: 0 auto 25px;
  }
}