@import "../../../stylesheets/base/variables.scss";

.site-footer {
  align-items: center;
  border-top: 1px solid $border-color;
  color: $subheader-color;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 20px auto 0;
  min-height: $footer-height;
  width: 80vw;

  @include tablet-and-larger {
    flex-direction: row;
  }

  @include desktop-only {
    margin-top: 40px;
    min-height: $footer-height-desktop;
  }
}
