@import "../../../stylesheets/base/variables.scss";

.playcard {
  // change link things here
  box-sizing: border-box;
  float: left;
  margin: 0;
  overflow: hidden;
  padding: 15px;
  padding-bottom: 0;
  text-decoration: none;
  width: 100%;

  &:hover {
    opacity: 0.75;
    transition: opacity 0.3s;
  }

  @include tablet-and-larger {
    width: 33.333%;
  }

  @include desktop-only {
  }
}

.playcard__image-wrapper {
  display: block;
  height: 0;
  margin: auto;
  max-width: 700px;
  overflow: hidden;
  padding-bottom: 75%;
  position: relative;
  width: 100%;

  @include tablet-and-larger {
    padding-bottom: 66.6667%;
  }

  img {
    /*
      keep image sizes consistent 
      https://www.sitepoint.com/maintain-image-aspect-ratios-responsive-web-design/
    */
    display: block;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    /*
        comment the next few lines out to crop images
        leave in to resize
      */
    // max-width: 100%;
    // max-height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

.playcard__title {
  // for long play titles
  min-height: 55px;
  // just to center the text vertically... silly
  display: flex;
  flex-direction: column;
  letter-spacing: 3.5px;
  font-family: $sub-header-font-family;
  justify-content: center;
  align-items: center;
  text-align: center;
}
