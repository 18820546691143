.page-content {
  margin: 50px 10vw 0;
  text-align: center;

  @include desktop-only {
    margin: 50px auto 0;
    max-width: 1200px;
  }

  h2 {
    font-size: 45px;
    line-height: 45px;
    margin: 25px 0 10px;
  }

  h3 {
    font-size: 35px;
    margin: 20px 0;
  }

  h4 {
    color: $subheader-color;
    font-size: 25px;
    font-style: italic;
    margin: 20px 0;
  }

  img {
    margin: 0 auto;
    width: 100%;
    height: auto;
  }
}
