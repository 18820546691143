@import "../../../stylesheets/base/variables.scss";

.plays-page-canvas {
  // min-height: 1000px;
  padding: 0;
  text-align: center;
  width: 100%;

  @include tablet-and-larger {
    margin: 50px 10px 0;
    padding: 0 2em;
  }

  @include desktop-only {
    margin: 50px auto 0;
    max-width: 1200px;
  }

  p {
    max-width: 95%;
    margin: 0 auto;

    @include mobile-only {
      margin-top: 25px;
    }
  }
}
