body {
  color: $base-text-color;
  font-family: $base-text-font-family;

  * {
    &::selection {
      background: $base-text-color-selection;
    }
  }
}

a:not([class]) {
  &:link,
  &:visited {
    color: $link-color;
  }

  &:active {
    background-color: $link-color-active;
  }

  &:hover {
    color: $link-color-hover;
  }
}

h1 {
  color: $base-text-color;
  font-family: $display-header-font-family;
  font-size: 4.5rem;
}

h2,
h3,
h4,
h5 {
  font-family: $header-font-family;
  // font-family: $header-font-family-2;
  font-weight: normal;
}
