@import "../../../stylesheets/base/variables.scss";

.navigation-menu {
  font-family: $header-font-family;
  font-size: 20px;
  margin: 0 auto;
  padding: 0 10px;
}

.navigation-menu--desktop {
  @include mobile-only {
    display: none;
  }
  max-width: 350px;

  ul {
    display: flex;
    padding: 0;
    justify-content: space-around;
  }
}

.navigation-menu--hamburger {
  border-bottom: 1px solid $border-color;
  margin: 30px 0 20px;
  padding-bottom: 20px;
  text-align: center;
  width: 100vw;

  button {
    @include remove-button-styling;
    color: $hamburger-button-color;
    width: 100%;

    &.navigation-menu--hamburger__button--expanded {
      margin-bottom: 20px;
    }
  }

  ul {
    text-align: center;

    li {
      margin-bottom: 5px;
    }
  }

  @include tablet-and-larger {
    display: none;
  }
}

.navigation-menu-list-item {
  font-size: 20px;
  text-transform: capitalize;

  a {
    text-decoration: none;
    border-bottom: none;
  }
}

a.navigation-menu-list-item--active {
  &:link,
  &:visited {
    color: $violet;
  }
  &:hover {
    color: $link-color-hover;
  }
}
