@import "../../../stylesheets/base/variables.scss";

/*
styling quotes:
https://tympanus.net/codrops/css_reference/quotes/
https://stackoverflow.com/questions/16325687/make-big-quotes-with-blockquote
http://jsfiddle.net/enigmarm/BpTdL/
*/

.press-item {
  margin-bottom: 20px;

  > blockquote {
    font-style: italic;
    margin-bottom: 10px;
    text-align: justify;
    position: relative;
    quotes: "“" "”";

    &:before,
    &:after {
      display: block;
      color: $accent-color;
      font-family: $display-header-font-family;
      font-size: 80px;
      position: absolute;
    }

    &:before {
      content: open-quote;
      left: -40px;
      top: -40px;
    }

    &:after {
      content: close-quote;
      right: -10px;
      bottom: -80px;
    }
  }
}
