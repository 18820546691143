/*
FONTS
*/

/// Display Header Font Family
$display-header-font-family: "Forum", "Georgia", "Times New Roman", Times, serif !default;

/// Header Font Family
$header-font-family: "Tajawal", "Helvetica Neue Light", "Helvetica Neue", "Helvetica", "Arial", sans-serif !default;

/// Sub Header Font Family
$sub-header-font-family: "Oxygen", "Helvetica Neue Light", "Helvetica Neue", "Helvetica", "Arial", sans-serif !default;

/// Base Body Text
$base-text-font-family: "Arvo", "Georgia", "Times New Roman", Times, serif !default;

/*
COLORS
*/
$apricot: #ffcab1;
$cobalt: #283c86;
$gunsteel: #6b717e;
$sierra: #bd513e;
$violet: #8b6389;

/// Base Text Color
$accent-color: $sierra;
$base-text-color: #333;
$base-text-color-selection: $apricot;
$border-color: $gunsteel;
$link-color: $cobalt;
$link-color-active: $apricot;
$link-color-hover: $sierra;
$hamburger-button-color: $gunsteel;
$subheader-color: $gunsteel;

/*
SPACING
*/

// Common Breakpoints
$mobile-max-width: 768px;
$tablet-min-width: 769px;
$tablet-max-width: 1024px;
$desktop-min-width: 1025px;

// Footer Size (with links 200)
$footer-height: 100px;
$footer-height-desktop: 200px;

@mixin mobile-only {
  @media only screen and (max-width: $mobile-max-width) {
    @content;
  }
}

@mixin tablet-and-smaller {
  @media only screen and (max-width: $tablet-max-width) {
    @content;
  }
}

@mixin tablet-only {
  @media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
    @content;
  }
}

@mixin tablet-and-larger {
  @media only screen and (min-width: $tablet-min-width) {
    @content;
  }
}

@mixin desktop-only {
  @media only screen and (min-width: $desktop-min-width) {
    @content;
  }
}

/*
MISC FUNCS
*/
// remove all button styling
@mixin remove-button-styling {
  background: inherit;
  color: inherit;
  border: none;
  padding: 0;
  font-family: inherit;
  cursor: pointer;
  outline: inherit;
}
